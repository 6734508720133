<template>
  <div class="text-container">
    "Tue Gutes und sprich darüber". Mit diesem Leitsatz bringen Sie Ihre
    Apotheke in der Öffentlichkeit in die richtige Wahrnehmung. Wir helfen Ihnen
    hier, Maßnahmen zu entwickeln, damit die Öffentlichkeit Sie und Ihre
    Apotheke favorisieren.
  </div>
</template>

<script>
export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content: "Wir beraten bei der Durchführung von PR für Ihre Apotheke.",
      },
    ],
  },
};
</script>